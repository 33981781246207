<template>
  <div class="bbc">
    <div style="background-color:white" class="d-flex justify-content-between justify-space-between">
      <router-link to="/login" style="padding:10px;">
        <img src="../../static/img/logo-full.png" alt="logo" style="margin-left: 25px;" />
      </router-link>
      <!-- <router-link to="/"> -->
      <!-- <sdFeatherIcons type="home" /> -->
      <!-- <img src="https://migrationade.com/pill.svg" alt="" srcset=""> -->

      <!-- </router-link> -->
      <!-- <div> -->
      <div class="text-grey-dim font-bold relative help">
        <a class="cursor-pointer hover:text-primary" @click="openDropDown" title="Need Help"
          ><strong> <img src="../../static/img/help.png" style="height:2rem"/></strong
        ></a>
        <HelpDropDown v-if="showDropDown" @close="showDropDown = false" />
      </div>
      <!-- </div> -->
    </div>
    <!-- <sdPageHeader title="Better Doctor" class="pgh" > -->

    <!-- <template > -->
    <!-- <img src="/client/src/static/img/logo.png" width="220px"> -->
    <!-- </template> -->
    <!-- </sdPageHeader> -->
    <AuthWrapper>
      <!-- <p class="auth-notice">Don&rsquo;t have an account? <router-link to="/auth/register">Sign up now</router-link></p> -->
      <div class="auth-contents">
        <a-form @finish="handleSubmit" :model="formState" class="white-box" layout="vertical">
          <!-- <p>Login to your Better Doctor platform account to continue</p> -->
          <sdHeading as="h3"> Enter Your New Password </sdHeading>
          <a-form-item name="username">
            <a-input
              type="password"
              v-model:value="formState.password"
              placeholder="Enter your New Password..."
              required
            />
          </a-form-item>
          <a-form-item name="password" initialValue="123456">
            <a-input
              type="password"
              v-model:value="formState.cpassword"
              placeholder="Re-enter your new password here..."
            />
          </a-form-item>
          <!-- <div class="auth-form-action">
          <a-checkbox @change="onChange">Keep me logged in</a-checkbox>
          <router-link class="forgot-pass-link" to="/auth/forgotPassword">
            Forgot password?
          </router-link>
        </div> -->
          <a-form-item>
            <!-- <sdButton class=" block" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton> -->

            <sdButton style="background-color: #1BABA8" size="large" htmlType="submit" type="primary" block>
              <!-- {{ formState.isReset ? 'Loading...' : 'Set Password' }} -->
              <div v-if="!formState.isReset">Send</div>
              <a-spin v-if="formState.isReset" color="white" size="large" />
            </sdButton>
          </a-form-item>
          <!-- <p class="form-divider">
          <span>Or</span>
        </p> -->
          <!-- <ul class="social-login">
          <li>
            <a class="google-signup" href="#">
              <img :src="require('@/static/img/google.png')" alt="" />
              <span>Sign in with Google</span>
            </a>
          </li>
          <li>
            <a class="facebook-sign" href="#">
              <FacebookOutlined />
            </a>
          </li>
          <li>
            <a class="twitter-sign" href="#">
              <TwitterOutlined />
            </a>
          </li>
        </ul> -->
          <!-- <div class="auth0-login">
          <a href="#" @click="() => lock.show()">
            Sign In with Auth0
          </a>
        </div> -->
        </a-form>
      </div>
    </AuthWrapper>
  </div>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
import { Notification } from 'ant-design-vue';
import { computed, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { AuthWrapper } from './style';
import { useRouter } from 'vue-router';
import { Auth0Lock } from 'auth0-lock';
import { auth0options } from '@/config/auth0';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useRoute } from 'vue-router';
import HelpDropDown from './HelpDropDown.vue';

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

const SignIn = {
  name: 'Reset',
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper, HelpDropDown },
  data() {
    return {
      email: '',
      showDropDown: false,

      // loader: false
    };
  },
  methods: {
    openDropDown() {
      console.log('-----');
      console.log(this.showDropDown);
      this.showDropDown = true;
      console.log('-----');
    },
  },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);
    const checked = ref(null);
    const router = useRouter();
    const values = ref(null);
    const route = useRoute();

    console.log(route.params.token);
    // console.log(params.token)
    let formState = reactive({
      customer_id: '',
      payment: '',
      credit: '',
      address: '',
      sms_notification: '',
      created_at: '',
      updated_at: '',
      isReset: false,
    });

    // const handleSubmit = value => {
    //     values.value = value;
    //   console.log('button clicked')
    //   console.log(formState.email)
    //   };

    const handleSubmit = () => {
      formState.isReset = true;

      console.log(formState.cpassword);

      axios
        // .post('https://api.getbetterdoctor.com/api/ResetPassword', {
        .post('post-resetpassword.php', {
          password: formState.password,
          token: route.params.token,
        })
        .then(res => {
          let response = res.data;
          console.log('response');

          if (response.status === false) {
            formState.isReset = false;
            Notification.open({
              message: 'Password Reset',
              description: 'Invalid Password',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });
            //   console.log('Login Credentials Are Faulted')
            console.log('Invalid Password');
          }

          if (response.status === true) {
            formState.isReset = true;
            Notification.open({
              message: 'Password Reset Succesfull',
              description: 'Password reset Done',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });
            console.log('Password changed');
            // console.log(response.item.id)
            //   Cookies.set('user',response.item)

            router.push('/login');

            // dispatch('login');
          }
        })
        .catch(err => {
          console.log('err');

          console.log(err);
        });

      // router.push('/');
      //  dispatch('login');
    };
    // const onChange = checked => {
    //   checked.value = checked;
    // };

    // const lock = new Auth0Lock(clientId, domain, auth0options);

    // lock.on('authenticated', authResult => {
    //   lock.getUserInfo(authResult.accessToken, error => {
    //     if (error) {
    //       return;
    //     }

    //     handleSubmit();
    //     lock.hide();
    //   });
    // });

    return {
      //   isLoading,
      //   checked,
      handleSubmit,
      //   onChange,
      formState,
      //   lock,
    };
  },
};

export default SignIn;
</script>
<style scoped>
.white-box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.bbc {
  background-image: url('../../static/img/background.png');
  /* background-image: url('https://pngimage.net/wp-content/uploads/2018/06/png-background-images-1-1.png'); */
  height: 100vh;
  /* height: 920px; */
  background-color: #f4f5f7;
  display: block;
}
/* @media (min-width: 668px) and (max-width: 1023px) {
  .bbc {
    width: 200%;
  }
  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 45px;
  }

  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
} */
/* @media (min-width: 1024px) and (max-width: 1700px) {

  .bbc {
   
    width: 200%;
  }
  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 45px;
  }
 
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
} */

@media (min-width: 1700px) and (max-width: 2700px) {
}
.white-box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.help {
  /* margin-inline: 500px; */
  /* margin-right: 900px; */
  /* float:right;
   padding: 1%; */
  position: relative;
  margin-right: 26px;
  color: gray;
  align-items: center;
  display: flex;
}
.help a,
a:visited,
a:hover {
  color: gray;
}
</style>
<style>
.ant-spin-dot-item {
  background-color: white !important;
}
</style>
